<template>
  <div class="product">
    <div class="zhft">
      <div class="zhft_box">
        <p>智慧饭堂</p>
        <p>让光盘成为习惯</p>
        <p>打造提前订餐、科学配餐、杜绝浪费的绿色饭堂用餐模式</p>
        <el-button>在线咨询</el-button>
      </div>
    </div>
    <div class="yylc">
      <p>应用流程</p>
      <div class="yylc_box">
        <img class="img" src="@/assets/images/product/zhft/yylc.png" />
      </div>
    </div>
    <div class="hxld">
      <p>核心亮点</p>
      <div class="hxld_box">
        <img class="img" id="img" src="@/assets/images/product/zhft/hxld.png" />
        <div class="hxld_box_content" id="title">
          <div class="hxld_box_item">
            <div class="title">
              <img
                class="icon"
                src="@/assets/images/product/zhft/hxld_icon1.png"
              />
              <p class="first_line">菜谱提前掌握</p>
              <p class="second_line">
                每周五下班前安排好下周餐谱，<br />并推送给用餐人员
              </p>
            </div>
            <div class="title">
              <img
                class="icon"
                src="@/assets/images/product/zhft/hxld_icon2.png"
              />
              <p class="first_line">线上支付</p>
              <p class="second_line">用餐人员订餐后<br />微信支付餐费</p>
            </div>
          </div>
          <div class="hxld_box_item">
            <div class="title">
              <img
                class="icon"
                src="@/assets/images/product/zhft/hxld_icon3.png"
              />
              <p class="first_line">点评互动</p>
              <p class="second_line">
                用餐人员可对餐品和服务进行点评，<br />精准提升服务水平
              </p>
            </div>
            <div class="title">
              <img
                class="icon"
                src="@/assets/images/product/zhft/hxld_icon4.png"
              />
              <p class="first_line">订餐分享</p>
              <p class="second_line">
                订餐人员可将订餐分享<br />供未订餐人员使用
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="axdc">
      <div class="axdc_box">
        <div class="axdc_box_content" id="title2">
          <div class="axdc_box_item">
            <div class="title">
              <img
                class="icon"
                src="@/assets/images/product/zhft/axdc_icon1.png"
              />
              <p class="first_line">按需订餐</p>
              <p class="second_line">
                用餐人员根据需求<br />按量订餐，杜绝浪费
              </p>
            </div>
            <div class="title">
              <img
                class="icon"
                src="@/assets/images/product/zhft/axdc_icon2.png"
              />
              <p class="first_line">食材统计</p>
              <p class="second_line">
                系统根据订餐情况自动统计食材量，<br />食堂按需采购，减少浪费
              </p>
            </div>
          </div>
          <div class="axdc_box_item">
            <div class="title">
              <img
                class="icon"
                src="@/assets/images/product/zhft/axdc_icon3.png"
              />
              <p class="first_line">收费统计</p>
              <p class="second_line">
                在线支付统计汇总，生成收费汇总<br />和明细报表，以便财务进行对账
              </p>
            </div>
            <div class="title">
              <img
                class="icon"
                src="@/assets/images/product/zhft/axdc_icon4.png"
              />
              <p class="first_line">扫码/刷脸取餐</p>
              <p class="second_line">
                报餐完成后生成二维码，<br />取餐人员扫码或刷脸取餐
              </p>
            </div>
          </div>
        </div>
        <img
          class="img"
          id="img2"
          src="@/assets/images/product/zhft/axdc.png"
        />
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  data () {
    return {

    }
  },
  mounted () {
    $(window).scroll(this.scrollBar)
  },
  methods: {
    scrollBar () {
      const h = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset
      var hxldH = $('.hxld').offset().top
      var axdcH = $('.axdc').offset().top
      if (
        h > hxldH - 500
      ) {
        $('#img').addClass('animation')
      }
      if (h > axdcH - 500) {
        $('#img2').addClass('animation')
      }
    }
  },
  destroyed () {
    $(window).unbind('scroll', this.scrollBar)
  }
}
</script>

<style scoped lang="scss">
@import './style/index.scss';
</style>
